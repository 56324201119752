// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lawyers-js": () => import("./../../../src/pages/lawyers.js" /* webpackChunkName: "component---src-pages-lawyers-js" */),
  "component---src-pages-our-victories-js": () => import("./../../../src/pages/our-victories.js" /* webpackChunkName: "component---src-pages-our-victories-js" */),
  "component---src-pages-practice-areas-js": () => import("./../../../src/pages/practice-areas.js" /* webpackChunkName: "component---src-pages-practice-areas-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-case-result-js": () => import("./../../../src/templates/case-result.js" /* webpackChunkName: "component---src-templates-case-result-js" */),
  "component---src-templates-lawyer-bio-js": () => import("./../../../src/templates/lawyer-bio.js" /* webpackChunkName: "component---src-templates-lawyer-bio-js" */),
  "component---src-templates-practice-area-category-js": () => import("./../../../src/templates/practice-area-category.js" /* webpackChunkName: "component---src-templates-practice-area-category-js" */),
  "component---src-templates-practice-area-js": () => import("./../../../src/templates/practice-area.js" /* webpackChunkName: "component---src-templates-practice-area-js" */)
}

